$(function() {
  $(document).on ('turbolinks:load', () => {
    $('#btn_postal').jpostal ({
      click : '#btn_postal',
      postcode : [
        '#postal_code'
      ],
      address : {
        '#prefecture_id' : '%3',   // # 都道府県
        '#city'          : '%4%5', // # 市区町村
        '#block'         : '%6%7'  // # 町域と大口事務所の番地と名称
      }
    });
  });
});
